import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import fontSizes from "styles/fontSizes";
import spacing from "styles/spacing";
import { black70, gray50 } from "styles/colors";
import PrivacyOptionsIcon from "public/icons/privacy-options.svg";
import {
  waitForOneTrustGeolocation,
  usPrivacyStates,
} from "utils/cookieConsent";

const PrivacyChoicesButtonStyles = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  user-select: text;

  ${fontSizes[0]}
  color: ${gray50};
  text-decoration: none;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  :hover {
    color: ${black70};
  }

  display: flex;
  align-items: center;
  svg {
    margin-left: ${spacing[2]};
  }
`;

const PrivacyChoicesButton = ({ wrapper }) => {
  let Wrapper = React.Fragment;
  if (wrapper) {
    Wrapper = wrapper;
  }

  // We only need to render this outside the US and in US states with data
  // privacy requirements. To be safe, let's render it initially and then
  // hide it once geolocation info is available.
  const [shouldRender, setShouldRender] = useState(true);
  useEffect(() => {
    waitForOneTrustGeolocation(3000).then((geo) => {
      if (
        geo?.country?.toUpperCase() === "US" &&
        !usPrivacyStates.includes(geo?.state?.toUpperCase())
      ) {
        // We are in a state where we can safely hide this.
        setShouldRender(false);
      }
    });
  }, []);

  const handleClick = () => {
    if (window && window.OneTrust && window.OneTrust.ToggleInfoDisplay) {
      window.OneTrust.ToggleInfoDisplay();
    }
  };

  if (shouldRender) {
    return (
      // eslint-disable-next-line react/jsx-fragments
      <Wrapper>
        <PrivacyChoicesButtonStyles onClick={handleClick}>
          Your Privacy Choices <PrivacyOptionsIcon width="1rem" height="1rem" />
        </PrivacyChoicesButtonStyles>
      </Wrapper>
    );
  }
  return null;
};

PrivacyChoicesButton.propTypes = {
  wrapper: PropTypes.node,
};

export default PrivacyChoicesButton;
