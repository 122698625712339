import PropTypes from "prop-types";
import styled from "styled-components";
import fontSizes from "styles/fontSizes";
import { spacingMixin } from "styles/spacing";
import { black70 } from "styles/colors";
import media from "styles/media";

// Quick lookup index for props we don't want to forward to the DOM element as attributes.
const NON_FORWARDED_PROPS = {
  spacing: true,
  spacingMedium: true,
  spacingLarge: true,
  size: true,
  sizeMedium: true,
  sizeLarge: true,
  caps: true,
  color: true,
  centered: true,
};

/* eslint-disable no-nested-ternary */
const Paragraph = styled.p.withConfig({
  // Prevent these props from being forwarded to the DOM element as attributes.
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !NON_FORWARDED_PROPS[prop] && defaultValidatorFn,
})`
  margin: 0;
  padding: 0;

  ${(props) => fontSizes[props.size]}
  font-weight: ${(props) => props.weight};

  ${(props) =>
    props.caps &&
    `
        text-transform: uppercase;
        letter-spacing: 1.05px;
    `}

  ${(props) =>
    props.sizeMedium || props.sizeMedium === 0
      ? media.medium`
        ${fontSizes[props.sizeMedium]}

    `
      : ""}

  ${(props) =>
    props.sizeLarge || props.sizeLarge === 0
      ? media.large`
      ${fontSizes[props.sizeLarge]}

  `
      : ""}

  ${(props) =>
    !!props.color &&
    `
      color: ${props.color};
  `}

  ${(props) => props.centered && "text-align: center;"};

  ${spacingMixin}
`;

Paragraph.defaultProps = {
  size: 2,
  sizeMedium: 4,
  weight: 400,
  color: black70,
  spacing: 0,
};

Paragraph.propTypes = {
  children: PropTypes.any,

  size: PropTypes.number,
  sizeMedium: PropTypes.number,
  sizeLarge: PropTypes.number,

  caps: PropTypes.bool,
  weight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
  centered: PropTypes.bool,

  spacing: PropTypes.number,
};

export default Paragraph;
