import styled from "styled-components";
import fontSizes from "styles/fontSizes";
import { white00, gray50, green60, red90 } from "styles/colors";

const Label = styled.label`
  ${fontSizes[1]}
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(18px, 13px) scale(1);
  pointer-events: none;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  background-color: ${white00};
  color: ${gray50};
  ${({ small }) =>
    small &&
    `
      ${fontSizes[0]}
      transform: translate(18px, 9px) scale(1);
  `}
  ${({ $selecting, value, small, placeholder }) =>
    ($selecting || value || placeholder) &&
    `
        transform: translate(${small ? "8" : "6"}px, -9px);
        ${fontSizes[0]}
        padding: 0.125rem 0.125rem;
        color: ${green60};
      `}
  ${({ $inactive }) =>
    $inactive &&
    `
      color: ${gray50};
      pointer-events: all;
      cursor: not-allowed;
    `}
  ${({ $error }) =>
    $error &&
    `
      color: ${red90}
  `}
`;

export default Label;
