/* eslint-disable import/prefer-default-export */

/*
  exported Button styles for use in components that need to
  look like a button, but cannot be (such as a div within a button container)
*/

import { css } from "styled-components";
import { green90 } from "styles/colors";

export const buttonTransitionStyles = css`
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
`;

export const buttonHoverStyles = css`
  background-color: ${green90};
  box-shadow: rgba(0, 0, 0, 0.4) 1px 1px 4px;
  cursor: pointer;
`;

export const buttonFocusStyles = css`
  outline: none;
  background-color: ${green90};
  box-shadow: 0px 0px 0px 2px rgb(178, 220, 255);
`;
