import styled from "styled-components";
import {
  white00,
  gray50,
  gray15,
  green60,
  black70,
  red50,
} from "styles/colors";
import fontSizes from "styles/fontSizes";

const InputField = styled.input`
  min-width: 128px;
  width: 100%;
  box-sizing: border-box;
  ${fontSizes[1]}
  font-family: inherit;
  background-color: ${white00};
  padding: 11px 1rem;
  border: 1px solid ${gray15};
  border-radius: 5px;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  color: ${black70};
  option {
    padding: 0;
  }
  :hover {
    cursor: text;
    border: 1px solid ${gray50};
  }
  :focus {
    outline: none;
    border: 2px solid ${green60};
    box-shadow: 0px 0px 0px 2px rgb(178, 220, 255);
    ${(props) => (props.small ? "padding: 6px 11px" : "padding: 10px 15px")};
  }
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  ${({ small }) =>
    small &&
    `
      ${fontSizes[0]}
      padding: 7px 0.75rem;
  `}
  ${({ $selecting, small }) =>
    $selecting &&
    `
      border: 2px solid ${green60};
      ${small ? "padding: 6px 11px" : "padding: 10px 15px"};
      :hover {
        border: 2px solid ${green60};
        ${small ? "padding: 6px 11px" : "padding: 10px 15px"};
      }
    `}
  ${({ disabled, small }) =>
    disabled &&
    `
      cursor: not-allowed;
      :hover {
        cursor: not-allowed;
        border: 1px solid ${gray15};
        ${small ? "padding: 7px 12px" : "padding: 11px 16px"};
      }
    `}
  ${({ $selecting, value, small }) =>
    !$selecting &&
    value !== "" &&
    value !== undefined &&
    `
        border: 2px solid ${green60};
        ${small ? "padding: 6px 11px" : "padding: 10px 15px"};
        :hover {
          border: 2px solid ${green60};
          ${small ? "padding: 6px 11px" : "padding: 10px 15px"};
        }
      `}
  ${({ $error, small }) =>
    $error &&
    `
      border: 2px solid ${red50};
      ${small ? "padding: 6px 11px" : "padding: 10px 15px"};
      :hover {
        border: 2px solid ${red50};
        ${small ? "padding: 6px 11px" : "padding: 10px 15px"};
      }
  `}
`;

export default InputField;
