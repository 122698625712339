import React from "react";
import styled from "styled-components";
import dynamic from "next/dynamic";
import paths from "utils/paths";
import media from "styles/media";
import fontSizes from "styles/fontSizes";
import spacing from "styles/spacing";
import { black70, gray50, gray15, green60, white00 } from "styles/colors";
import Link from "next/link";
import CLIASvg from "public/logos/CLIA.svg";
import GTSSvg from "public/logos/GoToSea.svg";
import Content from "components/layout/Content";
import Paragraph from "components/typography/Paragraph";
import PrivacyChoicesButton from "components/PrivacyChoicesButton";

const ShopperApprovedMilestoneAward = dynamic(
  () => import("components/shopperApproved/MilestoneAward"),
  {
    ssr: false,
  }
);

const today = new Date();

const copyrightText = `Copyright ${today.getFullYear()} © GoToSea, LLC`;

// Wrapper for full-width background color
const CLIAContainer = styled.div`
  margin-bottom: ${spacing[5]};
  padding: ${spacing[5]} 0;
  background-color: ${green60};
  text-align: center;

  a {
    color: ${white00};
  }
`;

const CLIALogo = styled(CLIASvg)`
  fill: ${white00};
  height: auto;
  width: 140px;
  ${media.medium`
    width: 168px;
  `}
`;

const USNContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: ${spacing[6]};
`;

const GTSLogo = styled(GTSSvg)`
  margin-bottom: 2rem;
  height: 3rem;
  width: auto;
`;

const Terms = styled.nav`
  ${fontSizes[0]}
  color: ${gray50};

  ul,
  p {
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
    list-style: none;
    margin-bottom: ${spacing[1]};
    padding: 0 ${spacing[2]};
    border-right: 1px solid ${gray15};
  }

  li:last-child {
    border-right: none;
  }

  a {
    display: inline-block;
    color: ${gray50};
    text-decoration: none;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    :hover {
      color: ${black70};
    }
  }
`;

const Footer = () => {
  return (
    <footer>
      <CLIAContainer>
        <Content>
          <Paragraph color={white00} centered $spacing={4} $spacingMedium={3}>
            GoToSea is headquartered in New York, NY. We serve customers in all
            50 U.S. states.{" "}
            <Link href="/about-us#introduction">Read more about us</Link>.
          </Paragraph>
          <Paragraph color={white00} centered $spacing={5}>
            We are a proud member of CLIA (Cruise Lines International
            Association).
          </Paragraph>
          <CLIALogo width="302" height="103" />
        </Content>
      </CLIAContainer>
      <USNContainer>
        <Content>
          <Paragraph
            size={0}
            sizeMedium={0}
            color={gray50}
            centered
            $spacing={5}
          >
            CA SOT #2146207-70; FL SOT #ST42879; HI SOT #TAR 7440; WA SOT
            #604699130
            <br /> GoToSea is <strong>not a participant</strong> in the
            California Travel Consumer Restitution Fund.
          </Paragraph>
          <ShopperApprovedMilestoneAward />
          <Paragraph>
            <Link href="/">
              <GTSLogo width="300" height="55" alt="Go To Sea" />
            </Link>
          </Paragraph>
          <Paragraph size={0} sizeMedium={0} $spacing={3}>
            {copyrightText}
          </Paragraph>
          <Terms>
            <ul>
              <li>
                <a href={paths.userAgreement().path}>User Agreement</a>
              </li>
              <li>
                <a href={paths.privacyPolicy().path}>Privacy Policy</a>
              </li>
              <PrivacyChoicesButton wrapper="li" />
            </ul>
          </Terms>
        </Content>
      </USNContainer>
    </footer>
  );
};

export default Footer;
