import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import fontSizes from "styles/fontSizes";
import {
  green60,
  white00,
  yellow60,
  black70,
  yellow70,
  purple30,
  purple60,
} from "styles/colors";
import spacing from "styles/spacing";
import {
  buttonTransitionStyles,
  buttonHoverStyles,
  buttonFocusStyles,
} from "./constants";

const ButtonBox = ({
  children,
  icon,
  image,
  onClick,
  disabled,
  className,
  ariaLabel,
}) => {
  return (
    <button
      type="button"
      className={className}
      disabled={disabled}
      onClick={onClick}
      aria-label={ariaLabel}
    >
      <div>
        {icon && <IconWrapper>{icon}</IconWrapper>}
        {image && <ImageWrapper>{image}</ImageWrapper>} {children}{" "}
      </div>
    </button>
  );
};

ButtonBox.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  icon: PropTypes.node,
  image: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
};

const IconWrapper = styled.span`
  position: relative;
  top: 2px;
`;

const ImageWrapper = styled.span`
  float: left;
`;

const Button = styled(ButtonBox)`
  font-family: "Source Sans Pro";
  -web-appearance: none;
  white-space: nowrap;
  ${fontSizes[1]}
  border: none;
  font-weight: 700;
  border-radius: 5px;
  background-color: ${green60};
  box-shadow: rgba(0, 0, 0, 0.2) 1px 1px;
  ${buttonTransitionStyles}
  padding: 0;
  color: ${white00};
  :hover {
    ${buttonHoverStyles}
  }
  :focus {
    ${buttonFocusStyles}
  }
  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
    :hover {
      box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 3px;
      background-color: ${green60};
    }
    :focus {
      box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 3px;
      background-color: ${green60};
    }
  }
  > div {
    box-sizing: border-box;
    border: 2px solid transparent;
    padding: 10px 16px;
    border-radius: 5px;
    ${buttonTransitionStyles}

    ${({ icon }) =>
      icon &&
      ` min-width: 103px;
        > ${IconWrapper} {
          padding-right: ${spacing[2]};
          > svg {
            width: 14px;
            height: 14px;
            transform: scale(1.88);
            fill: ${white00};
          }
        }
      `}

    ${({ icon, children }) =>
      icon &&
      !children &&
      ` min-width: 1.375rem;
        padding: 10px 13px !important;
        > ${IconWrapper} {
          padding-right: unset;
          > svg {
            transform: scale(1.57);
          }
        }
      `}

    ${({ image }) =>
      image &&
      ` min-width: 150px;
        > ${ImageWrapper} > img {
            width: 21px;
            height: 21px;
          }
      `}
  }

  ${({ small }) =>
    small &&
    `
      ${fontSizes[0]}
      > div {
        padding: 6px 16px;
      }
    `}

  ${({ icon, small }) =>
    small &&
    icon &&
    `
    > div {
      min-width: 87px;
      > ${IconWrapper} {
        padding-right: ${spacing[1]};
        > svg {
          width: 13px;
          height: 11px;
        }
      }
    }
  `}

  ${({ image, small }) =>
    image &&
    small &&
    `
    > div {
      min-width: 120px;
      > ${ImageWrapper}
        > img {
          width: 16px;
          height: 16px;
        }
    }
  `}

  ${({ secondary, icon }) =>
    secondary &&
    icon &&
    `
      > div > ${IconWrapper} > svg {
            fill: ${green60};
          }
    `}

  ${({ secondary, small }) =>
    secondary &&
    `
      box-shadow: none;
      color: ${green60};
      background-color: ${white00};
      border: 1px solid ${green60};
      > div {
        box-sizing: border-box;
        border: 1px solid transparent;
        padding: ${small ? "6px 15px" : "10px 15px"};
      }
      :hover {
        background-color: ${white00};
        box-shadow: none;
        border: 1px solid transparent;
        > div {
          border: 1px solid ${green60};
          box-shadow: 0px 0px 0px 1px ${green60};
        }
      }
      :focus {
        border: 1px solid transparent;
        background-color: ${white00};
        box-shadow: 0px 0px 0px 2px rgb(178,220,255);
        > div {
          border: 1px solid ${green60};
          box-shadow: 0px 0px 0px 1px ${green60};
        }
      }
      :disabled {
        :hover {
          background-color: ${white00};
          border: 1px solid ${green60};
          box-shadow: none;
          > div {
            box-sizing: border-box;
            border: 1px solid transparent;
            padding: ${small ? "6px 15px" : "10px 15px"};
          }
        }
        :focus {
          background-color: ${white00};
          border: 1px solid ${green60};
          box-shadow: none;
          > div {
            box-sizing: border-box;
            border: 1px solid transparent;
            padding: ${small ? "6px 15px" : "10px 15px"};
          }
        }
      }
    `}

    ${({ tertiary }) =>
    tertiary &&
    `
      background-color: ${yellow60} !important;
      color: ${black70} !important;
      :hover {
        background-color: ${yellow70} !important;
      }
      :focus {
        outline: none;
        background-color: ${yellow70} !important;
        box-shadow: 0px 0px 0px 2px rgb(254, 232, 166);
      }
    `}

    ${({ membership }) =>
    membership &&
    `
      background-color: ${purple30} !important;
      color: ${white00} !important;
      :hover {
        background-color: ${purple60} !important;
      }
      :focus {
        outline: none;
        background-color: ${purple60} !important;
        box-shadow: 0px 0px 0px 2px rgb(254, 232, 166);
      }
    `}
`;

export default Button;
