import styled from "styled-components";
import spacing from "styles/spacing";

export const contentPadding = spacing[4];

// Exported so that other components can import what this component is doing with width and match it if desired.
export const MAX_CONTENT_WIDTH = "1500px";

// prettier-ignore
const Content = styled.div.attrs(() => ({
    className: 'content',
}))`
    padding: 0 ${contentPadding};
    max-width: 100vw;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: ${ MAX_CONTENT_WIDTH }) {
        max-width: ${ MAX_CONTENT_WIDTH };
        box-sizing: content-box;
    }
`;

export default Content;
