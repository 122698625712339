import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import fontSizes from "styles/fontSizes";
import { red90 } from "styles/colors";
import InputField from "./InputField";
import Label from "./Label";
import ErrorIcon from "./ErrorIcon";

const Container = styled.div`
  position: relative;
  display: inline-block;
  ${({ $inactive }) =>
    $inactive &&
    `
      cursor: not-allowed;
    `}
`;

const ErrorText = styled.div`
  ${fontSizes[0]}
  color: ${red90};
  margin-top: 0.125rem;
`;

const TextInput = ({
  id,
  onChange,
  onBlur,
  disabled,
  name,
  small,
  errorMessage,
  onKeyDown,
  size,
  placeholder,
  className,
  inputRef,
  label = "",
  value = "",
  error = false,
  forceSelect = false,
  ...props
}) => {
  const [selecting, setSelecting] = useState(forceSelect);
  const handleOnBlur = () => {
    setSelecting(false);
    if (onBlur) {
      onBlur();
    }
  };

  return (
    <Container $inactive={disabled} className={className}>
      <Label
        $selecting={selecting}
        value={value}
        $inactive={disabled}
        htmlFor={id}
        small={small}
        $error={error}
        placeholder={placeholder}
      >
        {label}
      </Label>
      <InputField
        id={id}
        onChange={(e) => {
          if (onChange) {
            onChange(e.target.value, e);
          }
        }}
        onBlur={handleOnBlur}
        onClick={() => {
          setSelecting(true);
        }}
        onKeyDown={onKeyDown}
        value={value}
        $selecting={selecting}
        disabled={disabled}
        small={small}
        $error={error}
        name={name}
        size={size}
        placeholder={placeholder}
        ref={inputRef}
        {...props}
      />
      {error && (
        <>
          <ErrorIcon height="14px" fill={red90} small={small} />
          {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
        </>
      )}
    </Container>
  );
};

/* TODO - we may want a more standardized and global polyfill for this */
const NodeElement = typeof Element === "undefined" ? () => {} : Element;

TextInput.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  errorMessage: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  size: PropTypes.number,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  inputRef: PropTypes.shape({ current: PropTypes.instanceOf(NodeElement) }),
  forceSelect: PropTypes.bool,
};

export default TextInput;
