/* top level z-indexes as applied to each component */
/* eslint-disable import/prefer-default-export */

export const zModal = 99999;

// export const zPhotoGallery = 1200;

export const zOffCanvasOpen = 1001;
export const zMenuDropDown = 1000;
// export const zAnimatedContainerOpen = 1001; // in headers/OffCanvas
// export const zOverlayOpen = 800;            // in headers/OffCanvas

export const zStickyFooter = 700; // in headers/OffCanvas

// export const zAdhBase = 600;

// export const zCompareDrawer = 410;
// export const zSocialDropdownMenu = 400;

// export const zVideoPlayer = 400;

export const zRaisedContent = 10;
export const zContent = 1;

// export const zOverlayClosed = -1;
// export const zOffCanvasClosed = -1;
