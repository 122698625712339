import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Link from "next/link";
import { white00, black70, gray05 } from "styles/colors";
import fontSizes from "styles/fontSizes";
import media from "styles/media";

const Menu = styled.ul`
  position: absolute;
  background-color: ${white00};
  ${fontSizes[1]}
  font-weight: 400;
  width: max-content;
  min-width: 100%;
  display: block;
  list-style-type: none;
  padding: 0;
  margin: 0;
  box-shadow: 0px 0px 8px 0px rgba(51, 51, 51, 0.18);
  ${media.large`
    min-width: auto;
    left: 0;
    right: 0;
    width:auto;
    top: calc(100% + 3px);
    max-height: 400px;
    overflow: scroll;
  `}
`;

const MenuItem = styled(Link)`
  padding: 1rem;
  padding-left: 1rem;
  text-decoration: inherit;
  color: ${black70};
  display: block;
  :focus {
    background-color: ${gray05};
    outline: none;
    cursor: pointer;
  }
  ${({ active }) =>
    active &&
    `
    background-color: ${gray05};
    outline: none;
    cursor: pointer;
  `}
`;

const Autosuggest = ({
  items,
  className,
  setActiveMenuItem,
  activeMenuItem,
}) => {
  const handleMouseEnter = (itemIndex) => {
    setActiveMenuItem(itemIndex);
  };
  return (
    <Menu className={className} role="listbox">
      {items.map(({ label, link }, idx) => (
        <MenuItem
          key={label}
          href={link}
          active={activeMenuItem === idx}
          onMouseEnter={() => handleMouseEnter(idx)}
        >
          <li role="option" aria-selected={activeMenuItem === idx}>
            {label}
          </li>
        </MenuItem>
      ))}
    </Menu>
  );
};

Autosuggest.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({ link: PropTypes.string, label: PropTypes.string })
  ),
  className: PropTypes.string,
  setActiveMenuItem: PropTypes.func,
  activeMenuItem: PropTypes.number,
};

export default Autosuggest;
