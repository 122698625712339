import React, { useState } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import Link from "next/link";
import Chevron from "public/icons/chevron.svg";
import fontSizes from "styles/fontSizes";
import { black70, white00, gray05, green60, gray50 } from "styles/colors";

const menuItemStyle = css`
  ${fontSizes[1]}
  padding: 1rem 1rem;
  font-weight: 700;
  background-color: ${white00};
  color: ${black70};
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: none;
  border: none;
  width: 100%;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  :hover {
    outline: none;
    cursor: pointer;
    background-color: ${gray05};
  }
  :focus {
    outline: none;
    cursor: pointer;
    background-color: ${gray05};
  }
`;

const MenuItemLink = styled(Link)`
  ${menuItemStyle}
`;

const MenuItemButton = styled.button`
  ${menuItemStyle}
`;

const AccordionLink = styled(Link)`
  text-decoration: none;
  display: block;
  color: inherit;
  padding: 1rem;
  padding-left: 2rem;
  ${fontSizes[1]}
  font-weight: 400;
  color: ${black70};
  box-sizing: border-box;
  border-left: 2px solid transparent;
  :hover {
    background-color: ${gray05};
    outline: none;
    cursor: pointer;
    border-left: 2px solid ${green60};
  }
  :focus {
    background-color: ${gray05};
    outline: none;
    cursor: pointer;
    border-left: 2px solid ${green60};
  }
`;

const RotatingChevron = ({ className }) => {
  return (
    <Chevron height="26px" width="26px" fill={gray50} className={className} />
  );
};

RotatingChevron.propTypes = {
  className: PropTypes.string,
};

const StyledChevron = styled(RotatingChevron)`
  transition: 0.5s ease;
  ${({ isOpen }) =>
    isOpen &&
    `
    transform: rotate(-180deg);
  `}
`;

const Accordion = ({ label, link, items }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      {items ? (
        <MenuItemButton role="button" onClick={() => setIsOpen(!isOpen)}>
          <span>{label}</span>
          <StyledChevron isOpen={isOpen} />
        </MenuItemButton>
      ) : (
        <MenuItemLink href={link}>{label}</MenuItemLink>
      )}
      {isOpen &&
        items.map((item) => (
          <AccordionLink href={item.link} key={item.label} tabIndex="0">
            {item.label}
          </AccordionLink>
        ))}
    </div>
  );
};

Accordion.propTypes = {
  label: PropTypes.string,
  link: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({ link: PropTypes.string, label: PropTypes.string })
  ),
};

export default Accordion;
