const formatPortName = (port) => {
  return `${port.name}, ${port.state ? port.state.name : port.country.name}`;
};
const getSearchItems = ({ ports, destinations, text }) => {
  if (text.length < 2) {
    return [];
  }

  const searchPorts = ports.map((port) => ({
    ...port,
    name: formatPortName(port),
  }));

  const portMatches = searchPorts
    .filter((port) => {
      const { name } = port;
      return name.toLowerCase().includes(text.toLowerCase());
    })
    .map((port) => ({
      label: port.name,
      link: `/${port.canned_search_urlname}`,
    }));

  const destinationMatches = destinations
    .filter(({ name }) => name.toLowerCase().includes(text.toLowerCase()))
    .map((destination) => ({
      label: destination.name,
      link: `/${destination.canned_search_urlname}`,
    }));

  const matches = [...portMatches, ...destinationMatches];

  return matches;
};

export default getSearchItems;
