import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import AlertIcon from "public/icons/alert.svg";

const Icon = ({ fill, height, className }) => {
  return <AlertIcon fill={fill} height={height} className={className} />;
};

Icon.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
};

const ErrorIcon = styled(Icon)`
  position: absolute;
  right: 0.5rem;
  top: 14px;
  ${({ small }) =>
    small &&
    `
    top: 11px;
  `}
`;

export default ErrorIcon;
