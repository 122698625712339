import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Link from "next/link";
import Chevron from "public/icons/chevron.svg";
import media from "styles/media";
import spacing from "styles/spacing";
import fontSizes from "styles/fontSizes";
import { black70, green60, white00, gray05, gray15 } from "styles/colors";
import { zOffCanvasOpen, zMenuDropDown } from "styles/z-index";
/*
  Behavior ->
    on hover, or focus, the nav should be underlined and have the dropdown menu
      this means internal state change
    on leave hover -> reset
    onblur -> remove internal statechange, reset
*/

// TO DO -> replace these hardcoded values

const NavBox = styled.nav`
  display: none;
  position: relative;
  margin-left: 1rem;
  ${media.large`
    display: flex;
    align-items: stretch;
  `}
`;

const NavContainer = styled.div`
  position: relative;
  display: inline-block;
  margin: 0rem 1rem;
  &:hover div {
    display: block;
  }
`;

const NavSelect = styled.button`
  ${fontSizes[1]}
  color: ${black70};
  font-weight: 600;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  background-color: transparent;
  display: none;
  --webkit-appearance: none;
  padding: 0;
  border: none;
  position: relative;
  :hover {
    cursor: pointer;
  }
  :focus {
    outline: rgb(178, 220, 255) solid 4px;
  }
  ${media.medium`
    display: flex;
    align-items: center;
  `}
  height: 100%;
`;

const ChevronIcon = styled(Chevron)`
  margin-left: ${spacing[1]};
`;

const NavUnderline = styled.div`
  display: none;
  height: 0.125rem;
  background-color: ${green60};
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: ${zOffCanvasOpen}; /* ensures will show up over Menu::before */
`;

const NavAnchor = styled(Link)`
  text-decoration: none;
  ${fontSizes[1]}
  color: ${black70};
  font-weight: 600;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  position: relative;
  :hover {
    cursor: pointer;
  }
  :focus {
    outline: rgb(178, 220, 255) solid 4px;
  }
  ${media.medium`
    display: flex;
    align-items: center;
  `}
  height: 100%;
`;

const DROPDOWN_SHADOW_RADIUS = "8px";

const Menu = styled.div`
  display: none;
  position: absolute;
  top: 100%;

  ${fontSizes[1]}
  font-weight: 400;
  width: max-content;
  min-width: 100%;
  left: -1rem;

  &,
  & > div {
    background-color: ${white00};
    max-height: 340px;
  }
  box-shadow: 0px 0px ${DROPDOWN_SHADOW_RADIUS} 0px rgba(51, 51, 51, 0.18);
  /* prevents top box shadow from overlapping Header  */
  &::before {
    content: "";
    height: ${DROPDOWN_SHADOW_RADIUS};
    width: 100%;
    position: absolute;
    background: inherit;
    top: -${DROPDOWN_SHADOW_RADIUS};
    z-index: ${zMenuDropDown};
  }
`;

const MenuScrollable = styled.div`
  border-top: 1px solid ${gray15};
  overflow: scroll; /* apply scroll overflow here to allow top box shadow overlap fix */
`;

const MenuItem = styled(Link)`
  padding: 1rem;
  text-decoration: inherit;
  color: ${black70};
  display: block;
  :hover {
    background-color: ${gray05};
    outline: none;
    cursor: pointer;
  }
  :focus {
    background-color: ${gray05};
    outline: none;
    cursor: pointer;
  }
`;

const NavMenu = ({ items, className }) => {
  return (
    <Menu className={className}>
      <MenuScrollable>
        {items.map(({ label, link }) => (
          <MenuItem key={label} href={link} tabIndex="0">
            {label}
          </MenuItem>
        ))}
      </MenuScrollable>
    </Menu>
  );
};

NavMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({ link: PropTypes.string, label: PropTypes.string })
  ),
  className: PropTypes.string,
};

const NavButton = ({
  label,
  link,
  onBlur,
  onClick,
  onMouseEnter,
  onFocus,
  items,
}) => {
  return items ? (
    <NavContainer>
      <NavSelect
        onBlur={onBlur}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onFocus={onFocus}
      >
        <div>{label}</div>
        <ChevronIcon height="14px" width="14px" fill={black70} />
      </NavSelect>
      <NavUnderline />
      <NavMenu items={items} />
    </NavContainer>
  ) : (
    <NavContainer>
      <NavAnchor
        href={link}
        onBlur={onBlur}
        onMouseEnter={onMouseEnter}
        onFocus={onFocus}
      >
        {label}
      </NavAnchor>
      <NavUnderline />
    </NavContainer>
  );
};

NavButton.propTypes = {
  label: PropTypes.string,
  link: PropTypes.string,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onFocus: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string,
    })
  ),
};

const NavSection = ({ navItems }) => {
  // Not actually sure this is needed. I replaced with ref=useRef(null)
  // and everything seemed to still work.

  return (
    <NavBox>
      {navItems.map((item) => {
        const { label, link, items } = item;
        return (
          <NavButton key={label} label={label} link={link} items={items} />
        );
      })}
    </NavBox>
  );
};

NavSection.propTypes = {
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          link: PropTypes.string,
        })
      ),
    })
  ),
};

export default NavSection;
