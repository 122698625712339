import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import fontSizes from "styles/fontSizes";
import { green60, white00, gray50 } from "styles/colors";
import Heart from "public/icons/heart-o.svg";

const BadgeContainer = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50%;
  background: transparent;
  cursor: pointer;
`;

const BadgeIcon = styled.span`
  position: absolute;
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  color: ${white00};
  background: ${(props) => props.color};
  border-radius: 10px;
  ${fontSizes[0]};
  font-weight: bold;
  width: ${({ count }) => (count <= 9 ? "15px" : "20.36px")};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Badge = ({
  count,
  onClick,
  children,
  topPosition = "0px",
  rightPosition = "3px",
  backgroundColor = green60,
}) => {
  return (
    <BadgeContainer onClick={onClick}>
      {children || <Heart fill={gray50} height="33px" width="33px" />}
      {count !== 0 && (
        <BadgeIcon
          count={count}
          top={topPosition}
          right={rightPosition}
          color={backgroundColor}
        >
          {count}
        </BadgeIcon>
      )}
    </BadgeContainer>
  );
};

Badge.propTypes = {
  children: PropTypes.node,
  count: PropTypes.number,
  topPosition: PropTypes.string,
  rightPosition: PropTypes.string,
  backgroundColor: PropTypes.string,
  onClick: PropTypes.func,
};

export default Badge;
